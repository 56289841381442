import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  statusCode: null,
  description: null,
  actionModal: () => {},
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.open = action.payload.open;
      state.statusCode = action.payload.statusCode;
      state.description = action.payload.description;
      if (action.payload?.actionModal) {
        state.actionModal = action.payload?.actionModal;
      }
    },
  },
});

export const { setModal } = modalSlice.actions;

export default modalSlice.reducer;
